import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isFullScreen = new Subject();
 
  constructor() { }
  public gotoFullScreen(): void {
    this.isFullScreen.next(true);
  }
}
