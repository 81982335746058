import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators";
import { IMap } from 'src/app/entities/IMap';
import { IMapNodeInformation } from 'src/app/entities/IMapNodeInformation';
const keysToCodecs = {
  $oid: "$oid",
  $binary: "$binary",
  $symbol: "$symbol",
  $numberInt: "$numberInt",
  $numberDecimal: "$numberDecimal",
  $numberDouble: "$numberDouble",
  $numberLong: "$numberLong",
  $minKey: "$minKey",
  $maxKey: "$maxKey",
  $regularExpression: "$regularExpression",
  $timestamp: '$timestamp'
};
@Injectable()
export class ApplicationService {
  host = "http://localhost:3000";
  constructor(private http: HttpClient) {

  }
  getData(module: string = "", query: string = "") {
    return this.http.get<Array<any>>('http://localhost:3000/maps', {
      params: new HttpParams()
        .set('query', query)
    }).pipe(
      map(res => res["payload"])
    );
  }

  /*DYNAMIC LAYOUTS*/
  post(module: string, type: string, body: any = {}, query: string = "") {
    return this.http.post<Array<any>>(`${this.host}/api/${module}/${type}`, body, {
      params: new HttpParams()
        .set('query', query)
    }).pipe(
      map(res => res["payload"])
    );
  }

  /* FIXED API */
  createMap(mapToCreate: IMap) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/maps/incoming_webhook/create";
    return this.http.post(serviceURL, mapToCreate).pipe(
      map(res => res["payload"])
    );
  }
  updateMap(mapUpdate: IMap) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/maps/incoming_webhook/update";
    return this.http.post(serviceURL, mapUpdate).pipe(
      map(res => res["payload"])
    );
  }
  getAllMaps(client: string) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/maps/incoming_webhook/get_all";
    return this.http.post(serviceURL, { client: client }).pipe(
      map(res => {
        // @ts-ignore
        if (res.type === "ejson") {
          const responsePayload = res["payload"];
          this.walkObj(responsePayload, this.checkAndFixNull);
          return responsePayload;
        } else {
          return res["payload"];
        }

      })
    );
  }

  // NODE INFO's
  createNodeInfo(nodeInfoToCreate: IMapNodeInformation) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/node_info/incoming_webhook/create";
    return this.http.post(serviceURL, nodeInfoToCreate).pipe(
      map(res => res["payload"])
    );
  }
  updateNodeInfo(mapUpdate: IMap) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/node_info/incoming_webhook/update";
    return this.http.post(serviceURL, mapUpdate).pipe(
      map(res => res["payload"])
    );
  }
  getAllNodeInfos(client: string) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/node_info/incoming_webhook/get";
    return this.http.post(serviceURL, { client: client }).pipe(
      map(res => {
        // @ts-ignore
        if (res.type === "ejson") {
          const responsePayload = res["payload"];
          this.walkObj(responsePayload, this.checkAndFixNull);
          return responsePayload;
        } else {
          return res["payload"];
        }

      })
    );
  }

  getAllPaths(client: string) {
    const serviceURL = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ismartnav_development-ichab/service/path/incoming_webhook/get";
    return this.http.post(serviceURL, { client: client }).pipe(
      map(res => {
        // @ts-ignore
        if (res.type === "ejson") {
          const responsePayload = res["payload"];
          this.walkObj(responsePayload, this.checkAndFixNull);
          return responsePayload;
        } else {
          return res["payload"];
        }
      })
    );
  }

  // what = Object.prototype.toString;
  walkObj(obj, fn) {
    const me = this;
    var wo = obj.toString();
    if (wo == "[object Object]") {
      Object.keys(obj).forEach(function (key) {
        fn(obj, key);
        var item = obj[key], w = item.toString();
        if (w == "[object Object]" || w == "[object Array]" || (w.indexOf("[object Object],") > -1)) {
          me.walkObj(item, fn);
        }
      });
    }
    else if (wo == "[object Array]" || (wo.indexOf("[object Object],") > -1)) {
      obj.forEach(function (item, ix) {
        fn(obj, ix);
      });
      obj.forEach(function (item, ix) {
        var w = item.toString();
        if (w == "[object Object]" || w == "[object Array]") {
          me.walkObj(item, fn);
        }
      });
    }
  }

  checkAndFixNull(parent, key) {
    var value = parent[key], w = value.toString();
    if (value && value[keysToCodecs.$numberInt]) {
      parent[key] = parseInt(value[keysToCodecs.$numberInt]);
    }
    // if ((w == "[object Object]") && (value.TEXT === null) && (value['@nil'] === true)) {
    //   parent[key] = null;
    // }
  }

}