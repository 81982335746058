import { Component, OnInit, Input } from '@angular/core';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNode, MapNode } from 'src/app/entities/MapNode';
import { IMap } from 'src/app/entities/IMap';

@Component({
  selector: 'app-marker-details',
  templateUrl: './marker-details.component.html',
  styleUrls: ['./marker-details.component.scss']
})
export class MarkerDetailsComponent implements OnInit {
  isVisible: boolean = false;
  map: string;
  mapNode: IMapNode;
  tags: string;
  @Input('mapId') mapId: string;
  @Input('map') Map: IMap;
  constructor(
    private nodeService: NodesManagerService
  ) {
    nodeService.mapNodeSelected.subscribe((details: any) => {
      if (details) {
        this.mapNode = details.mapNode;
        this.map = details.map;
        this.isVisible = details.mapNode ? true : false;
      }
    })
  }
  updateNode() {
    // this.mapNode.clusterId = this.mapNode.label;
    this.nodeService.setAsMarkedNode(this.mapId, this.mapNode.name, this.mapNode);
  }
  ngOnInit() {
  }
  close() {
    this.isVisible = false;
  }
}
