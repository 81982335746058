export interface IMapNode {
    mapId: string; // same for all nodes in a cluters formation a king of (clusterId) which is pointing to the nodename of the root node of cluster whic is lablleld
    clusterId: string; // same for all nodes in a cluters formation a king of (clusterId) which is pointing to the nodename of the root node of cluster whic is lablleld
    name: string; // unique to the node and it is an internal name
    label?: string; // unique and linked with the path and information details
    tags?: Array<string>; // linked with quick link buttons at bottom
    x: number;
    y: number;
    h: number;
    w: number;
    isRoute: boolean;
    isMarked: boolean;
    isClustered: boolean;
    active: boolean;
}
export class MapNode implements IMapNode {
    mapId: string;
    clusterId: string;
    name: string;
    label?: string;
    public x: number;
    public y: number;
    public h: number;
    public w: number;
    isRoute: boolean;
    isMarked: boolean;
    isClustered: boolean;
    active: boolean;
    constructor(mapId: string, x: number, y: number, h: number, w: number) {
        const NodeX = (x - (x % w)),
            NodeY = (y - (y % h));
        this.x = NodeX;
        this.y = NodeY;
        this.h = h;
        this.w = w;
        this.name = `${NodeX}-${NodeY}`
        this.clusterId = `${mapId}__${NodeX}-${NodeY}`
    }
    syncClusterId(){
        this.clusterId = `${this.mapId}__${this.x}-${this.y}`
    }
    setLabel(label: string) {
        this.label = label;
    }

}