import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { IMap } from 'src/app/entities/IMap';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnChanges, OnInit {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapId) {
      const mapId: SimpleChange = changes.mapId;
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      // this.Map = this.nodeService.getMap(this.MapName);
      this.fetchMap();
    }
  }
  @Input('mapId') mapId: string;
  @Input('map') Map: IMap;
  constructor(
    private nodeService: NodesManagerService
  ) { }

  ngOnInit() {
    console.log(this.mapId, this.Map);
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
    });
  }


}
