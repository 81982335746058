import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatDialogModule,
  MatSelectModule,
  MatSidenavModule,
  MatMenuModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  // MatListModule
} from "@angular/material";
// import {MatListModule} from '@angular/material/list';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { SpinnerComponent } from './shared/spinner.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
import { ApplicationService } from './core/api/application-service.service';
import { ErrorComponent } from './error/error.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MapViewerComponent } from './map-viewer/map-viewer.component';
import { NavigatorComponent } from './map/navigator/navigator.component';
import { CommonModule } from '@angular/common';
import { MainScreenComponent } from './main-screen/main-screen.component';
import { ImageSliderComponent } from './screens/image-slider/image-slider.component';
import { ImageButtonsComponent } from './screens/image-buttons/image-buttons.component';
import { BottomPanelComponent } from './screens/bottom-panel/bottom-panel.component';
import { TopPanelComponent } from './screens/top-panel/top-panel.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { MapBuilderComponent } from './map-builder/map-builder.component';
import { ItemDetailsComponent } from './screens/item-details/item-details.component';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FullscreenDirective } from './fullscreen.directive';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    UserComponent,
    RegisterComponent,
    ErrorComponent,
    MapViewerComponent,
    NavigatorComponent,
    MainScreenComponent,
    ImageSliderComponent,
    ImageButtonsComponent,
    BottomPanelComponent,
    TopPanelComponent,
    MapBuilderComponent,
    ItemDetailsComponent,
    FullscreenDirective
    // DevicesListComponent,
    // DevicesRegisterComponent,
    // ContentListComponent,
    // ContentUploadComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    CommonModule,
    NgxUiLoaderModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatMenuModule,
    MatTabsModule,
    MatAutocompleteModule,
    Ng2PanZoomModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    NgxUiLoaderModule,
    SlideshowModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false, onSameUrlNavigation: 'reload' }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule
  ],
  providers: [AuthService, UserService, UserResolver, AuthGuard, ApplicationService, { provide: StorageBucket, useValue: environment.firebase.storageBucket }],
  bootstrap: [AppComponent],
  exports: [NavigatorComponent, ImageSliderComponent, ImageButtonsComponent, BottomPanelComponent, TopPanelComponent, ItemDetailsComponent],
  entryComponents: [NavigatorComponent, ImageSliderComponent, ImageButtonsComponent, BottomPanelComponent, TopPanelComponent, ItemDetailsComponent]
})
export class AppModule { }
