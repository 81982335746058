import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNode } from 'src/app/entities/MapNode';
import { IMap } from 'src/app/entities/IMap';
import { IMapNodeInformation } from 'src/app/entities/IMapNodeInformation';

export interface StateGroup {
  letter: string;
  names: string[];
}

export const _filter = (opt: Array<IMapNodeInformation>, value: string): Array<IMapNodeInformation> => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.title.toLowerCase().indexOf(filterValue) === 0);
};
export const _stringfilter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
@Component({
  selector: 'app-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss']
})
export class TopPanelComponent implements OnInit {
  selectedMap: string;
  @Output('mapSelectionChanged') mapSelectionChanged = new EventEmitter();
  @Input('enableFrom') enableFrom: boolean = false;

  maps: Array<IMap>;
  avaliableNodes: Array<IMapNodeInformation>;
  /*
  {
    letter: 'A',
    names: ['Alabama', 'Alaska', 'Arizona', 'Arkansas']
  }
   */
  stateGroups: StateGroup[] = [];

  stateGroupOptions: Observable<StateGroup[]>;
  stateForm: FormGroup = this._formBuilder.group({
    stateGroup: '',
  });
  location: string; // clusterId
  from: string; // clusterId
  constructor(
    private nodeService: NodesManagerService,
    private _formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.stateGroupOptions = this.stateForm.get('stateGroup')!.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => this._filterGroup(value))
      );
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.maps = this.nodeService.getMaps();
    this.nodeService.getNodeInfos().then((nodes: Array<IMapNodeInformation>) => {
      this.avaliableNodes = nodes;
      const byStartAplphabet = {};
      nodes.forEach(node => {
        const start = node.title.substring(0, 1);
        if (!byStartAplphabet[start]) {
          byStartAplphabet[start] = [];
        }
        byStartAplphabet[start].push(node);
      });
      Object.keys(byStartAplphabet).forEach(key => {
        this.stateGroups.push({
          letter: key,
          names: byStartAplphabet[key]
        })
      });
    });
  }

  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({ letter: group.letter, names: _stringfilter(group.names, value) }))
        .filter(group => group.names.length > 0);
    }

    return this.stateGroups;
  }
  onOptionSelected(selectedLocation: string) {
    this.onSearch();
  }
  onStartOptionSelected(selectedLocation: string) {
    this.nodeService.setStartNode(this.from);
  }
  onSearch() {
    const seletedNode = this.avaliableNodes.find(nod => nod.clusterId === this.location);
    this.mapSelectionChanged.emit(seletedNode.mapId);
    setTimeout(() => {
      console.log(seletedNode.mapId);
      this.nodeService.triggerDetails(seletedNode.mapId, this.location);
      this.nodeService.highlight(seletedNode.mapId, this.location, "navigation");
    }, 1000);

  }
  onFloorSelectionChanged() {
    this.mapSelectionChanged.emit(this.selectedMap)
  }
}
