import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { LayoutComponent } from '../map/layout/layout.component';
import { DrawComponent } from '../map/draw/draw.component';
import { MarkerComponent } from '../map/marker/marker.component';
import { MarkerDetailsComponent } from '../map/marker-details/marker-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
import { CommonModule } from '@angular/common';
import { ClusterComponent } from '../map/cluster/cluster.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    LayoutComponent,
    DrawComponent,
    MarkerComponent,
    MarkerDetailsComponent,
    ClusterComponent
  ],
  imports: [
    // BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DrawComponent, 
    MarkerComponent, 
    MarkerDetailsComponent,
    LayoutComponent,
    ClusterComponent
   ],
  providers: [ MenuItems ]
})
export class SharedModule { }
