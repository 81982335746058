// https://stackoverflow.com/questions/33426829/rxjs-mousedrag-complete-callback
// https://codepen.io/HunorMarton/post/handling-complex-mouse-and-touch-events-with-rxjs
// https://stackoverflow.com/questions/28350881/issue-on-drawing-straight-line-with-mouse-down-and-move-event

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { ApplicationService } from '../core/api/application-service.service';
import { IMap } from '../entities/IMap';
import { ActivatedRoute } from '@angular/router';
import { NodesManagerService } from '../core/nodes-manager.service';

@Component({
  selector: 'app-map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss']
})
export class MapViewerComponent implements OnChanges, OnInit {

  ngOnChanges(changes: SimpleChanges): void {
    const mapId: SimpleChange = changes.mapId;
    if (mapId) {
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      this.fetchMap();
    }
  }

  Map: IMap;
  @Input('mapId') mapId: string;
  @Input('mode') mode: string;
  @Input('scale') scale: number;

  constructor(
    private route: ActivatedRoute,
    private nodeService: NodesManagerService
  ) {
  }

  ngOnInit() {
    this.mapId = this.route.snapshot.params.mapId || this.mapId;
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
    });
  }
}
