import { Component, OnInit } from '@angular/core';
import { NodesManagerService } from '../core/nodes-manager.service';
import { ActivatedRoute } from '@angular/router';
import { IMap } from '../entities/IMap';

@Component({
  selector: 'app-map-builder',
  templateUrl: './map-builder.component.html',
  styleUrls: ['./map-builder.component.scss']
})
export class MapBuilderComponent implements OnInit {
  Mode: string = "view";
  mapId: string;
  Map: IMap;
  constructor(
    private route: ActivatedRoute,
    private nodeService: NodesManagerService
  ) {

  }
  setMode(mode) {
    this.Mode = mode;
  }
  ngOnInit() {
    this.mapId = this.route.snapshot.params.mapId;
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
    });
  }
  save() {
    this.nodeService.saveMapNodes(this.mapId).then();
  }
  highLight(item: string, target: string) {
    this.nodeService.highlight(this.mapId, item, target);
  }
  normalizeRoute() {
    this.nodeService.normalize(this.mapId).then(() => {
      this.highLight('routes', 'draw');
    });
  }
}

