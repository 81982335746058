import { Component, OnInit } from '@angular/core';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNodeInformation } from 'src/app/entities/IMapNodeInformation';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {
  show: boolean;
  nodeInfo: IMapNodeInformation;
  constructor(private nodeService: NodesManagerService) {
    nodeService.showItemDetails.subscribe((details: { mapId: string, clusterId: string }) => {
      this.show = true;
      this.nodeInfo = nodeService.getNodeInfo(details.mapId, details.clusterId);
      if (!this.nodeInfo) {
        this.show = false;
      }
      setTimeout(() => {
        this.nodeService.triggerHideDetails(this.nodeInfo.mapId, this.nodeInfo.clusterId);
        this.close();
      }, 1000 * 30);
    })
  }

  ngOnInit() {
  }

  close() {
    this.nodeService.triggerHideDetails(this.nodeInfo.mapId, this.nodeInfo.clusterId);
    this.show = false;
  }
  showDirections() {
    if (this.nodeInfo.clusterId) {
      this.nodeService.setEndNode(this.nodeInfo.clusterId);
      this.nodeService.triggerShowRoute();
      this.show = false;
    }
  }
}
