import { Component, OnInit, Input } from '@angular/core';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';

@Component({
  selector: 'app-image-buttons',
  templateUrl: './image-buttons.component.html',
  styleUrls: ['./image-buttons.component.scss']
})
export class ImageButtonsComponent implements OnInit {
  @Input('mapId') mapId: string;
  
  constructor(private nodeService: NodesManagerService) { }

  ngOnInit() {
  }
  highLight(item: string, target: string) {
    this.nodeService.highlight(this.mapId, item, target);
  }
}
