import { Component, OnInit, Renderer2, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { LayoutService } from '../core/layout.service';
import { ActivatedRoute } from '@angular/router';
import { NodesManagerService } from '../core/nodes-manager.service';
import { PanZoomConfig, PanZoomAPI, PanZoomModel } from 'ng2-panzoom';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss']
})
export class MainScreenComponent implements OnInit {
  elableFromSelector: boolean;
  startClusterId: string;
  endClusterId: string;
  private panzoomConfig: PanZoomConfig = new PanZoomConfig({
    initialPanX:0,
    initialPanY:0,
    zoomLevels: 10,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    freeMouseWheelFactor: 0.01,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left'
  });

  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;
  public panzoomModel: PanZoomModel;
  private modelChangedSubscription: Subscription;
  //public contentItems = contentItems;
  public canvasWidth = 4000;
  public initialZoomHeight: number = null; // set in resetZoomToFit()
  public initialZoomWidth = this.canvasWidth;
  public initialised = false;
  public scale = this.getCssScale(this.panzoomConfig.initialZoomLevel);
  private isMobile = false;



  constructor(
    private el: ElementRef,
    private layoutService: LayoutService,
    private nodesManagerService: NodesManagerService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) {

  }
  selectedMap: string;
  ngOnInit() {
    // this.mapId = this.route.snapshot.params.mapId;
    if (this.nodesManagerService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodesManagerService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
    this.layoutService.gotoFullScreen();
  }
  fetchMap() {
    this.nodesManagerService.navigationMapChange.subscribe((details: { operatingMapId: string, nextMapId: string, startIndex: number }) => {
      //debugger;
      this.nodesManagerService.setSelectedMap(details.nextMapId);
      this.selectedMap = details.nextMapId;
      this.nodesManagerService.triggerShowRoute(details.nextMapId, details.startIndex);
      setTimeout(() => {
        if (details.startIndex !== 0) {
          this.nodesManagerService.drawFullScreenImageOnNavigator.next("esc");
        }
        setTimeout(() => {
          this.nodesManagerService.playPauseNavigation.next(true);
        }, 2000);
      }, 100);
      this.changeDetector.detectChanges();




      // this.zone.run(() => {
      //   console.log('enabled time travel');
      // });
    });




    this.startClusterId = this.route.snapshot.params.startClusterId;
    this.endClusterId = this.route.snapshot.params.endClusterId;
    if (!this.startClusterId && !this.endClusterId) {
      this.elableFromSelector = true;
    }
    if (this.startClusterId) {
      this.nodesManagerService.setStartNode(this.startClusterId);
    }
    if (this.endClusterId) {
      this.nodesManagerService.setEndNode(this.endClusterId);
    }
    if (this.startClusterId && this.endClusterId) {
      this.nodesManagerService.triggerShowRoute();
    }


    this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'background-color', 'rgb(146,77,100)');
    this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'overflow', 'hidden');
    this.apiSubscription = this.panzoomConfig.api.subscribe((api: PanZoomAPI) => this.panZoomAPI = api);
    this.modelChangedSubscription = this.panzoomConfig.modelChanged.subscribe((model: PanZoomModel) => this.onModelChanged(model));
    this.isMobile = this.isMobileDevice();
    if (this.isMobile) {
      // this.contentItems = this.contentItems.slice(0, 13);
    }



  }

  onMapSelectionChanged(map: string) {
    this.selectedMap = map;
    this.nodesManagerService.setSelectedMap(map);
  }








  ngAfterViewInit(): void {
    this.resetZoomToFit();
    this.initialised = true;
    this.changeDetector.detectChanges();
  }



  ngOnDestroy(): void {
    this.modelChangedSubscription.unsubscribe();
    this.apiSubscription.unsubscribe();
  }



  private isMobileDevice(): boolean {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }



  onModelChanged(model: PanZoomModel): void {
    this.panzoomModel = JSON.parse(JSON.stringify(model));
    this.scale = this.getCssScale(this.panzoomModel.zoomLevel);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }



  private getCssScale(zoomLevel: any): number {
    // log.debug('PanZoomComponent: getCssScale()');
    return Math.pow(this.panzoomConfig.scalePerZoomLevel, zoomLevel - this.panzoomConfig.neutralZoomLevel);
  }



  resetZoomToFit(): void {
    let height = 2200;
    let width = 4000;
    this.panzoomConfig.initialZoomToFit = { x: 0, y: 0, width: width, height: height };
    this.initialZoomHeight = height;
  }



  public zoomIn(): void {
    this.panZoomAPI.zoomIn();
  }



  public zoomOut(): void {
    this.panZoomAPI.zoomOut();
  }



  public resetView(): void {
    this.panZoomAPI.resetView();
  }



  public panLeft100(): void {
    this.panZoomAPI.panDelta({ x: -100, y: 0 });
  }



  public panRight100(): void {
    this.panZoomAPI.panDelta({ x: 100, y: 0 });
  }



  public panUp100(): void {
    this.panZoomAPI.panDelta({ x: 0, y: -100 });
  }



  public panDown100(): void {
    this.panZoomAPI.panDelta({ x: 0, y: 100 });
  }



  public panLeftPercent(): void {
    this.panZoomAPI.panDeltaPercent({ x: -20, y: 0 });
  }



  public panRightPercent(): void {
    this.panZoomAPI.panDeltaPercent({ x: 20, y: 0 });
  }



  public panUpPercent(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: -20 });
  }



  public panDownPercent(): void {
    this.panZoomAPI.panDeltaPercent({ x: 0, y: 20 });
  }



  public panToPoint(): void {
    this.panZoomAPI.panToPoint({ x: 0, y: 0 });
    // this.panZoomAPI.panToPoint( { x: 2400, y: 4270 } );
    // this.panZoomAPI.panToPoint( { x: 2400, y: 0 } );
    // this.panZoomAPI.panToPoint( { x: 0, y: 4270 } );
  }


}
