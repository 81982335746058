import { Component, OnInit, SimpleChange, SimpleChanges, OnChanges, ElementRef, ViewChild, Input } from '@angular/core';
import { IMap } from 'src/app/entities/IMap';
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNode, MapNode } from 'src/app/entities/MapNode';
import { IMapNodeInformation } from 'src/app/entities/IMapNodeInformation';
import { IPath } from 'src/app/entities/IPath';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnChanges, OnInit {
  requestAnimFrame: (callback: () => void) => void = (function () {
    return window.requestAnimationFrame ||
      (<any>window).webkitRequestAnimationFrame ||
      (<any>window).mozRequestAnimationFrame ||
      (<any>window).oRequestAnimationFrame ||
      (<any>window).msRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60, new Date().getTime());
      };
  })();
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapId) {
      const mapId: SimpleChange = changes.mapId;
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      this.fetchMap();
      //this.Map = this.nodeService.getMap(this.MapName);
    }
  }
  // a reference to the canvas element from our template
  @ViewChild('navigatorcanvas') public canvas: ElementRef;
  // setting a width and height for the canvas
  private _imgMarker;	// the source image
  private _imgWalk;	// the source image
  private _imgFootprint;	// the source image
  private _imgEscalator;	// the source image
  private _imgOffer;	// the source image


  private _imgStartMarker;	// the source image
  private _imgEndMarker;	// the source image

  private cx: CanvasRenderingContext2D;
  private canvasEl: HTMLCanvasElement
  @Input('mapId') mapId: string;
  @Input('map') Map: IMap;
  @Input('scale') scale: number;

  nodeInfo: IMapNodeInformation;
  listNodesToHighlight: Array<IMapNode> = [];
  pathToNavigate: IPath;

  startCluster: Array<IMapNode> = [];
  endCluster: Array<IMapNode> = [];
  currentStepIndex = 0;
  isPaused: boolean = false;
  constructor(
    private nodeService: NodesManagerService
  ) {
    // cache images for marker
    this._imgMarker = new Image();
    this._imgMarker.onload = (() => this.imagesReady.bind(this));
    this._imgMarker.src = "/assets/icons/marker.png";
    this._imgOffer = new Image();
    this._imgOffer.onload = (() => this.imagesReady.bind(this));
    this._imgOffer.src = "/assets/images/offer.png";

    this._imgEscalator = new Image();
    this._imgEscalator.onload = (() => this.imagesReady.bind(this));
    this._imgEscalator.src = "/assets/images/escalator.png";

    this._imgStartMarker = new Image();
    this._imgStartMarker.onload = (() => this.imagesReady.bind(this));
    this._imgStartMarker.src = "/assets/icons/start_marker.png";
    this._imgEndMarker = new Image();
    this._imgEndMarker.onload = (() => this.imagesReady.bind(this));
    this._imgEndMarker.src = "/assets/icons/end_marker.png";

    this._imgWalk = new Image();
    this._imgWalk.onload = (() => this.imagesReady.bind(this));
    this._imgWalk.src = "/assets/icons/walking.png";


    this._imgFootprint = new Image();
    this._imgFootprint.onload = (() => this.imagesReady.bind(this));
    this._imgFootprint.src = "/assets/icons/footprint.png";

    nodeService.mapNodesToHighlight.subscribe((highlight: any) => {
      if (highlight.target && highlight.nodes) {
        if (highlight.target === "navigation") {
          this.listNodesToHighlight = highlight.nodes;
        }
      }
    })
    nodeService.playPauseNavigation.subscribe((play) => {
      this.isPaused = !play;
    });
    nodeService.drawFullScreenImageOnNavigator.subscribe((imgId) => {
      if (imgId === "esc") {
        this.cx.fillStyle = 'rgb(0,0,0, 0.5)';
        this.cx.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        this.cx.drawImage(
          this._imgEscalator,
          (this.canvasEl.width / 2) - 150,
          (this.canvasEl.height / 2) - 150,
          300,
          300);
      }
    })

    nodeService.showItemDetails.subscribe((details: { mapId: string, clusterId: string }) => {
      this.nodeInfo = nodeService.getNodeInfo(details.mapId, details.clusterId)
      setTimeout(() => {
        this.listNodesToHighlight = [];
      }, 1000 * 30);
    })

    nodeService.hideItemDetails.subscribe((details: { mapId: string, clusterId: string }) => {
      this.listNodesToHighlight = [];

    });
    nodeService.navigation.subscribe((details: { mapId: string, startIndex: number }) => {
      this.operatingMapId = details.mapId;
      this.pathToNavigate = nodeService.getActiveNaigation();
      this.currentStepIndex = details.startIndex;
    })
  }
  imagesReady() {

  }

  ngOnInit() {
    console.log(this.mapId, this.Map);
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
      this.loadCanvas()
    });
  }
  public loadCanvas() {
    // get the context
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');

    // set the width and height
    this.canvasEl.width = this.Map.width;
    this.canvasEl.height = this.Map.height;
    this.captureEvents(this.canvasEl);
    this.requestAnimFrame(this.anmiationLoop.bind(this));
  }
  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        map(event => event)
      )
      .subscribe((res: MouseEvent) => {
        const rect = canvasEl.getBoundingClientRect();
        let currentPos = {
          x: (res.clientX - rect.left),
          y: (res.clientY - rect.top)
        };
        if (this.scale != 0) {
          // console.log("x: " + (res.clientX - rect.left));
          // console.log("scaled x: " + Math.floor((res.clientX - rect.left) / this.scale));
          // console.log("y: " + (res.clientY - rect.top));
          // console.log("scaled y: " + Math.floor((res.clientY - rect.top) / this.scale));
          currentPos = {
            x: Math.floor((res.clientX - rect.left) / this.scale),
            y: Math.floor((res.clientY - rect.top) / this.scale)
          };

        }
        this.putMarkerOnLocation(currentPos);
      });
  }
  putMarkerOnLocation(currentPos) {
    const mapNode = new MapNode(this.Map.mapId, currentPos.x, currentPos.y, this.Map.blockSize, this.Map.blockSize);
    const selectedNode: IMapNode = this.nodeService.getMatchingNode(this.Map.mapId, mapNode);
    if (selectedNode && selectedNode.isClustered) {
      this.endCluster = this.nodeService.getClusteredNodes(this.Map.mapId, selectedNode.clusterId);
    }

    if (selectedNode) {
      //setTimeout(() => {
      this.nodeService.triggerDetails(this.Map.mapId, selectedNode.clusterId);
      //}, 1000);
      //this.cx.drawImage(this._image, mapNode.x, mapNode.y, this.Map.markerSize, this.Map.markerSize);
      //this.nodeService.setNodeSelection(this.Map.mapId, selectedNode);
    }
  }
  anmiationLoop() {
    if (!this.isPaused) {
      this.cx.clearRect(0, 0, this.Map.width, this.Map.height);
      this.highlightNodes();
      this.drawPath();
    }
    // this.movePoiner(node);
    // this.drawFoorPrint(node);
    this.requestAnimFrame(this.anmiationLoop.bind(this));
  }
  private highlightNodes() {
    this.listNodesToHighlight.forEach(nodeToHighlight => {
      this.rippleEffectOnHighlighter(nodeToHighlight, "rgba(0, 0, 200, 0.5)", 80);
      this.drawHighlighterImage(nodeToHighlight, this._imgMarker);
    })
  }
  private drawHighlighterImage(mapNode: IMapNode, marker: CanvasImageSource) {
    this.cx.drawImage(marker, mapNode.x - 50, mapNode.y - 50, this.Map.markerSize + 100, this.Map.markerSize + 100);
  }

  rippleAngle = 0;
  private rippleEffectOnHighlighter(mapNode: IMapNode, color: string, radiusMultipler: number = 10) {
    // draw the circle
    this.cx.beginPath();

    var radius = mapNode.w + radiusMultipler * Math.abs(Math.cos(this.rippleAngle));
    this.cx.arc(mapNode.x + mapNode.w / 2, mapNode.y + mapNode.h / 2, radius, 0, Math.PI * 2, false);
    this.cx.closePath();

    // color in the circle
    this.cx.fillStyle = color || "rgba(0, 0, 200, 0.5)";
    this.cx.fill();

    this.rippleAngle += Math.PI / 64;
  }

  operatingMapId: string;
  private drawPath() {

    if (this.startCluster && this.startCluster.length > 0) {
      this.startCluster.forEach((nod) => this.drawCluster(nod));
    }
    if (this.endCluster && this.endCluster.length > 0) {
      this.endCluster.forEach((nod) => this.drawCluster(nod));
    }

    if (this.pathToNavigate && this.pathToNavigate.path) {
      const effectiveIdx = Math.floor(this.currentStepIndex);
      const effectiveMapNode = this.pathToNavigate.path[effectiveIdx];
      this.movePoiner(effectiveMapNode);
      //console.log(effectiveMapNode.mapId);
      if (!this.operatingMapId || this.operatingMapId.length === 0) {
        this.operatingMapId = effectiveMapNode.mapId;
      }
      if (effectiveMapNode.mapId !== this.operatingMapId) {
        //console.log('############### Trigger Map change ###########');
        this.isPaused = true;
        this.nodeService.triggerNavigationMapChange(
          this.operatingMapId,
          effectiveMapNode.mapId,
          effectiveIdx
        );
      }
      for (let idx = 0; idx < effectiveIdx; idx += 1) {
        const footP = this.pathToNavigate.path[idx];
        if (footP.mapId === this.operatingMapId) {
          console.log(footP.label);
          if (footP.clusterId === 'central-ground-floor__2350-550') {
            this.showOfferImage(footP);
          }
          this.drawFootPrint(footP);
        }
      }
      if (effectiveIdx === this.pathToNavigate.path.length - 1) {
        this.currentStepIndex = 0;
      }
      this.currentStepIndex += .15;
      console.log(this.currentStepIndex);
      const startNode: IMapNode = this.pathToNavigate.path[0];
      const endNode: IMapNode = this.pathToNavigate.path[this.pathToNavigate.path.length - 1];

      if (!this.startCluster) {
        this.startCluster = this.nodeService.getClusteredNodes(startNode.mapId, startNode.clusterId);
      }

      if (!this.endCluster) {
        this.endCluster = this.nodeService.getClusteredNodes(endNode.mapId, endNode.clusterId);
      }
      if (startNode.mapId === this.operatingMapId) {
        this.rippleEffectOnHighlighter(startNode, "rgba(200, 0, 0, 0.5)", 80);
        this.drawHighlighterImage(startNode, this._imgStartMarker);
      }
      if (endNode.mapId === this.operatingMapId) {
        this.rippleEffectOnHighlighter(endNode, "rgba(0, 200, 0, 0.5)", 80);
        this.drawHighlighterImage(endNode, this._imgEndMarker);
      }
    }
  }
  showOfferImage(footP: IMapNode) {
    this.cx.drawImage(this._imgOffer, footP.x - 200, footP.y - 200, 200, 200);
  }
  private drawCluster(node: IMapNode) {
    // draw the circle
    this.cx.fillStyle = "rgba(0,0,220,.3)";
    this.cx.fillRect(node.x, node.y, node.w, node.h);

  }
  private movePoiner(mapNode: IMapNode) {
    // draw the circle
    this.cx.drawImage(this._imgWalk, mapNode.x, mapNode.y - 20, this.Map.markerSize + 100, this.Map.markerSize + 100);

  }
  private drawFootPrint(mapNode: IMapNode) {
    // draw the circle
    // for (let idx = 0; idx < (this.x - this.Map.markerSize); idx += this.Map.markerSize) {
    this.cx.drawImage(this._imgFootprint, mapNode.x, mapNode.y - 10, this.Map.markerSize + 20, this.Map.markerSize + 20);
    // }
  }

}

