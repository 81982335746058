import { Injectable } from '@angular/core';
import { IMapNode, MapNode } from '../entities/MapNode';
import { Subject } from 'rxjs';
import { ApplicationService } from './api/application-service.service';
import { IMap } from '../entities/IMap';
import { client } from '../AppConstants';
import { IMapNodeInformation } from '../entities/IMapNodeInformation';
import { eachSeries } from 'async';
import { IPath } from '../entities/IPath';
@Injectable({
  providedIn: 'root'
})
export class NodesManagerService {


  navigationMapChange = new Subject();
  navigation = new Subject();
  activeNavigation: IPath;
  playPauseNavigation = new Subject();
  drawFullScreenImageOnNavigator = new Subject();
  startCluster: IMapNodeInformation;
  endCluster: IMapNodeInformation;
  selectedMap: string;
  setStartNode(clusterId: String) {
    this.startCluster = this.NodeInfos.find((nodInf: IMapNodeInformation) => nodInf.clusterId === clusterId);
  }
  setEndNode(clusterId: String) {
    this.endCluster = this.NodeInfos.find((nodInf: IMapNodeInformation) => nodInf.clusterId === clusterId);
  }
  getActiveNaigation() {
    return this.activeNavigation;
  }
  triggerShowRoute(mapId?: string, startIndex?: number) {
    if (this.startCluster && this.endCluster) {
      this.activeNavigation = this.Paths.find(path => path.start_clusterId === this.startCluster.clusterId && path.end_clusterId === this.endCluster.clusterId)
      this.navigation.next({
        mapId: mapId || this.selectedMap,
        startIndex: startIndex || 0
      });
    }
    // throw new Error("Method not implemented.");
  }
  triggerNavigationMapChange(operatingMapId: string, nextMapId: string, startIndex: number) {
    //throw new Error("Method not implemented.");
    this.navigationMapChange.next({
      operatingMapId,
      nextMapId,
      startIndex
    });
  }
  setSelectedMap(map: string) {
    this.selectedMap = map;
  }


  NodeInfos: Array<IMapNodeInformation> = [];
  Paths: Array<IPath> = [];
  fetchPaths(): Promise<boolean> {
    const me = this;
    return new Promise<boolean>((resolve, reject) => {
      me.applicationService.getAllPaths(client).subscribe(pathsResponse => {
        me.Paths = pathsResponse.data
        resolve(true);
      });
    })
  }

  fetchNodeInfos(): Promise<boolean> {
    const me = this;
    return new Promise<boolean>((resolve, reject) => {
      me.applicationService.getAllNodeInfos(client).subscribe(mapsResponse => {
        me.NodeInfos = mapsResponse.data
        resolve(true);
      });
    })
  }

  getNodeInfo(mapId: string, clusterId: string): IMapNodeInformation {
    return this.NodeInfos.find((nodeInfo: IMapNodeInformation) => nodeInfo.mapId === mapId && nodeInfo.clusterId === clusterId);
  }
  getClusteredNodes(mapId: string, clusterId: string): Array<IMapNode> {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    if (map.nodes) {
      return map.nodes.filter(node => node.clusterId === clusterId);
    } else {
      return [];
    }
  }

  getNodeInfos(mapId: string = ""): Promise<Array<IMapNodeInformation>> {
    return new Promise<Array<IMapNodeInformation>>((resolve, reject) => {
      let nodes = [];
      if (mapId === "") {
        nodes = this.NodeInfos;
      } else {
        nodes = this.NodeInfos.filter((nodeInf: IMapNodeInformation) => nodeInf.mapId === mapId);
      }
      resolve(nodes);
    })
  }
  isInitialized: boolean = false;
  initialized = new Subject();
  mapNodeSelected = new Subject();
  mapNodesToHighlight = new Subject();
  showItemDetails = new Subject();
  hideItemDetails = new Subject();

  Maps: Array<IMap> = [];
  constructor(private applicationService: ApplicationService) {
    if (!this.Maps || this.Maps.length === 0) {
      this.fetchMaps();
    }
  }
  public getMaps(): Array<IMap> {
    debugger;
    return this.Maps;//Object.keys(this.Maps).map((mapKey) => this.Maps[mapKey]);
  }

  // public getMap(mapId: string): IMap {
  //   return this.Maps.find(map => map.mapId = mapId);
  // }

  public fetchMap(mapId: string): Promise<IMap> {
    return new Promise((resolve, reject) => {
      const map = this.Maps.find(mp => mp.mapId === mapId);
      if (map) {
        resolve(map);
      } else {
        reject("No Map Found!")
      }
    })
  }
  public fetchMaps() {
    const me = this;
    me.applicationService.getAllMaps(client).subscribe(mapsResponse => {
      me.Maps = mapsResponse.data;
      me.fetchPaths().then((isFetched) => {
        // cb();
      });
      me.fetchNodeInfos().then((isFetched) => {
        me.isInitialized = true;
        me.initialized.next(true);
      });
    })
  }
  public saveMapNodes(mapId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const uniqueNodes = [];
      const hashMap = new Map();
      const map = this.Maps.find(mp => mp.mapId === mapId);
      if (map.nodes) {
        for (const item of map.nodes) {
          if (!hashMap.has(item.name)) {
            hashMap.set(item.name, true);    // set any value to Map
            item.clusterId = `${mapId}__${item.name}`
            uniqueNodes.push(item);
          }
        }

        // hook to update for unique cluster id
        // const uniqueclusterIdNodes = uniqueNodes.map((nd => {
        //   nd.clusterId = map.mapId + "-" + nd.clusterId;
        //   return nd;
        // }))
        map.nodes = uniqueNodes;
      }
      this.applicationService.updateMap(map).subscribe((mapsResponse) => {
        if (mapsResponse.success === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
  public insertNode(mapId: string, node: IMapNode) {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    if (!map.nodes) {
      map.nodes = [];
    }
    const existingNode = map.nodes.find(nod => nod.name === node.name);
    if (existingNode) {
      return existingNode;
    } else {
      map.nodes.push(node);
      return node;
    }
  }
  public deleteNode(mapId: string, node: IMapNode) {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    if (!map.nodes) {
      map.nodes = [];
    }
    const idxOfExistingNode = map.nodes.findIndex(nod => nod.name === node.name);
    if (idxOfExistingNode > -1) {
      map.nodes.splice(idxOfExistingNode, 1);
    }
    return node;
  }


  public setAsMarkedNode(mapId: string, nodeName: string, node: IMapNode) {
    const map = this.Maps.find(mp => mp.mapId === mapId);

    const mapNode: IMapNode = map.nodes.find((node: IMapNode) => node.name === nodeName);
    mapNode.label = node.label;
    mapNode.isMarked = true;
    // mapNode.tags = node.tags;
  }

  public getMarkedNodes(mapId: string): Array<IMapNode> {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    return map.nodes.filter((node: IMapNode) => node.isMarked);
  }

  public getNodes(mapId: string) {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    return map.nodes;
  }

  public getLabeledNodes(mapId: string) {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    return map.nodes.map(node => node.label !== undefined);
  }
  public getMatchingNode(mapId: string, mapNode: IMapNode): IMapNode {
    const map = this.Maps.find(mp => mp.mapId === mapId);
    return map ? map.nodes.find(node => (node.x === mapNode.x && node.y === mapNode.y)) : undefined;
  }

  selectedNode: IMapNode;
  public getSelectedNode(MapId: string): IMapNode {
    return this.selectedNode;
  };
  public setNodeSelection(MapId: string, mapNode: IMapNode): void {
    this.selectedNode = mapNode;
    this.mapNodeSelected.next({ MapId, mapNode });
  }
  public highlight(mapId: string, tag: string, target: string): void {
    let nodesToHighlight: Array<IMapNode>;
    const map = this.Maps.find(mp => mp.mapId === mapId);
    if (map) {
      if (tag === "routes") {
        nodesToHighlight = map.nodes;
      } else if (tag === "marker") {
        nodesToHighlight = map.nodes.filter(node => node.label && node.label.length > 0);
      } else if (tag && tag.length > 1) {
        nodesToHighlight = map.nodes.filter(node => node.tags && node.tags.indexOf(tag)>-1);
      }
    } else if (tag) {
      //if (this.NodeInfosByMap[mapId]) {
      const nodesInfosToHighlight = this.NodeInfos.filter(node => node.tags && node.tags.length > 0 && node.tags.indexOf(tag) > -1);
      if (nodesInfosToHighlight) {
        nodesToHighlight = nodesInfosToHighlight.map((nodeInfo) => {
          const nodesWithSameClusterId = map.nodes.find(finder => finder.clusterId === nodeInfo.clusterId)
          return nodesWithSameClusterId;
        });
        // }
      }
    }
    this.mapNodesToHighlight.next({ target: target, nodes: nodesToHighlight });
  }
  normalize(mapId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const map = this.Maps.find(mp => mp.mapId === mapId);
      const mapNodes = [...map.nodes];
      for (let jdx = 0; jdx < mapNodes.length; jdx++) {
        const node = mapNodes[jdx];

        const diagonalConnectedNode = [], correctlyConnected = [];
        for (let idx = 0; idx < mapNodes.length; idx++) {
          const nod = mapNodes[idx];
          if ((nod.x === node.x - node.w && nod.y === node.y - node.h)
            || (nod.x === node.x - node.w && nod.y === node.y + node.h)
            || (nod.x === node.x + node.w && nod.y === node.y - node.h)
            || (nod.x === node.x + node.w && nod.y === node.y + node.h)) {
            diagonalConnectedNode.push(nod);
          }

          if ((nod.x === node.x && nod.y === node.y - node.h)
            || (nod.x === node.x && nod.y === node.y + node.h)
            || (nod.x === node.x - node.w && nod.y === node.y)
            || (nod.x === node.x + node.w && nod.y === node.y)) {
            correctlyConnected.push(nod);
          }
          if (correctlyConnected.length > 0) {
            // nothing to do
          } else if (diagonalConnectedNode.length > 0) {
            // add new node in between
            diagonalConnectedNode.forEach((diagonalNode) => {
              if ((diagonalNode.x === node.x - node.w && diagonalNode.y === node.y - node.h)
                || (diagonalNode.x === node.x - node.w && diagonalNode.y === node.y + node.h)) {
                const newNode = new MapNode(mapId, node.x - node.w, node.y, node.h, node.w);
                map.nodes.push(newNode);
              }
              if ((diagonalNode.x === node.x + 1 && diagonalNode.y === node.y - 1)
                || (diagonalNode.x === node.x + 1 && diagonalNode.y === node.y + 1)) {
                const newNode = new MapNode(mapId, node.x + 1, node.y, node.h, node.w);
                map.nodes.push(newNode);
              }
            })
          }
        }
      }
      resolve(true);
    })

  }
  triggerDetails(mapId: string, clusterId: string) {
    this.showItemDetails.next({ mapId, clusterId });
  }
  triggerHideDetails(mapId: string, clusterId: string) {
    this.hideItemDetails.next({ mapId, clusterId });
  }
}
