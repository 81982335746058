import { Injectable, OnInit } from '@angular/core';
import { ApplicationService } from '../../core/api/application-service.service';

export interface Menu {
  icon: string;
  label: string;
  name: string;
  order: number,
  platform: string;
  type: string;
  url: string;
}

@Injectable()
export class MenuItems {
  MENUITEMS = [];

  constructor(private applicationService: ApplicationService) {
    this.MENUITEMS = [
      {
        icon: "all_inbox",
        label: "Kiosk Home",
        name: "map",
        order: 0,
        platform: "all",
        type: "link",
        url: "/main"
      },{
        icon: "all_inbox",
        label: "Dashboard",
        name: "dashboard",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/dashboard"
      },{
        icon: "all_inbox",
        label: "Maps",
        name: "map",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/maps/list"
      },{
        icon: "all_inbox",
        label: "Node Info",
        name: "searchpoints",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/searchpoints/list"
      },
      {
        icon: "all_inbox",
        label: "Devices",
        name: "devices",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/devices/list"
      },
      {
        icon: "all_inbox",
        label: "Add Devices",
        name: "add_devices",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/devices/register"
      },
      {
        icon: "all_inbox",
        label: "Content",
        name: "content",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/content/list"
      },
      {
        icon: "all_inbox",
        label: "Add Content",
        name: "add_content",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/content/upload"
      },{
        icon: "all_inbox",
        label: "Publish Content",
        name: "publish_content",
        order: 0,
        platform: "all",
        type: "link",
        url: "/pages/content/publish"
      }
    ];

    // this.applicationService.post("menu", "list", { sortBy: "order", sortOrder: "asc" }).subscribe((payload) => {
    //   this.MENUITEMS = payload.data;
    //   // [
    //   //   { state: '/starter', name: 'Applications', type: 'link', icon: 'av_timer' },
    //   //   { state: '/starter/student/tabs', name: 'Students', type: 'link', icon: 'assistant' },
    //   //   { state: '/starter/mail-comm/list', name: 'E-Mail Bot', type: 'link', icon: 'android' }]

    // });
  }


  getMenuitem(): Menu[] {
    return this.MENUITEMS;
  }
}
