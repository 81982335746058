import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise, map } from 'rxjs/operators'
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNode, MapNode } from 'src/app/entities/MapNode';
import { IMap } from 'src/app/entities/IMap';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})
export class ClusterComponent implements OnInit {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapId) {
      const mapId: SimpleChange = changes.mapId;
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      this.fetchMap();
      //this.Map = this.nodeService.getMap(this.MapName);
    }
  }
  // a reference to the canvas element from our template
  @ViewChild('clustercanvas') public canvas: ElementRef;
  // setting a width and height for the canvas
  private _image_hightlight;	// the source image

  private cx: CanvasRenderingContext2D;
  @Input('clusterRefNode') clusterRefNode: IMapNode;
  @Input('mapId') mapId: string;
  @Input('map') map: IMap;
  constructor(
    private nodeService: NodesManagerService
  ) {
    // cache images for marker

    this._image_hightlight = new Image();
    this._image_hightlight.onload = (() => this.imagesReady.bind(this));

    this._image_hightlight.src = "/assets/icons/marker_highlight.png";


  }
  imagesReady() {

  }

  highlightClusers(node) {
    debugger;
  }
  ngOnInit() {
    console.log(this.mapId, this.map);
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }


  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.map = map;
      this.loadCanvas();
      this.paintedNodes = [];
      this.clusterRefNode = this.nodeService.getSelectedNode(this.mapId);
      if (this.clusterRefNode) {
        const clusteredNodes: Array<IMapNode> = this.nodeService.getClusteredNodes(this.mapId, this.clusterRefNode.clusterId);
        if (clusteredNodes && clusteredNodes.length > 0) {
          clusteredNodes.forEach((nod) => {
            this.drawNode(nod);
          });
        }
      } else {
        alert("Please select a marker for forming cluster");
      }
    });
  }
  public loadCanvas() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    // set the width and height
    canvasEl.width = this.map.width;
    canvasEl.height = this.map.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // debugger;
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        // this.drawOnCanvas(prevPos, currentPos);
        const mapNode = this.nodeService.insertNode(this.map.mapId, new MapNode(this.map.mapId, currentPos.x, currentPos.y, this.map.blockSize, this.map.blockSize));
        mapNode.isClustered = true;
        mapNode.clusterId = this.clusterRefNode.clusterId;
        this.drawNode(mapNode);
      });
  }

  paintedNodes = [];
  private drawNode(node: IMapNode) {
    if (!this.cx) { return; }
    if (node.x === 0 || node.x === this.map.width || node.x === this.map.width - this.map.blockSize || node.y === 0 || node.y === this.map.height || node.y === this.map.height - this.map.blockSize) {
      return;
    }
    if (this.paintedNodes.indexOf(node.name) > -1) {
      return;
    } else {
      this.paintedNodes.push(node.name);
      this.cx.fillStyle = "rgba(0,0,220,.3)";
      this.cx.fillRect(node.x, node.y, node.w, node.h);
    }
  }
}
