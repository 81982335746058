import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise, map } from 'rxjs/operators'
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { IMapNode, MapNode } from 'src/app/entities/MapNode';
import { IMap } from 'src/app/entities/IMap';

@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnChanges, OnInit {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapId) {
      const mapId: SimpleChange = changes.mapId;
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      this.fetchMap();
      //this.Map = this.nodeService.getMap(this.MapName);
    }
  }
  // a reference to the canvas element from our template
  @ViewChild('markercanvas') public canvas: ElementRef;
  // setting a width and height for the canvas
  private _image;	// the source image

  private cx: CanvasRenderingContext2D;
  @Input('mapId') mapId: string;
  @Input('map') Map: IMap;
  constructor(
    private nodeService: NodesManagerService
  ) {
    // cache images for marker
    this._image = new Image();
    this._image.onload = (() => this.imagesReady.bind(this));

    this._image.src = "/assets/icons/marker.png";

    nodeService.mapNodesToHighlight.subscribe((highlight: any) => {
      if (highlight.target && highlight.nodes) {
        if (highlight.target === "marker") {
          highlight.nodes.forEach((node) => {
            this.drawMarker(node);
          })
        }
      }
    })

  }
  imagesReady() {

  }

  ngOnInit() {
    console.log(this.mapId, this.Map);
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
      this.loadCanvas()
    });
  }
  public loadCanvas() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    // set the width and height
    canvasEl.width = this.Map.width;
    canvasEl.height = this.Map.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        map(event => event)
      )
      .subscribe((res: MouseEvent) => {
        const rect = canvasEl.getBoundingClientRect();

        const currentPos = {
          x: res.clientX - rect.left,
          y: res.clientY - rect.top
        };
        this.putMarkerOnLocation(currentPos);
      });
  }
  private drawMarker(mapNode: IMapNode) {
    this.cx.drawImage(this._image, mapNode.x, mapNode.y, this.Map.markerSize, this.Map.markerSize);
  }
  private putMarkerOnLocation(
    currentPos: { x: number, y: number }
  ) {
    const mapNode = new MapNode(this.Map.mapId, currentPos.x, currentPos.y, this.Map.blockSize, this.Map.blockSize);
    const selectedNode: IMapNode = this.nodeService.getMatchingNode(this.Map.mapId, mapNode);
    if (selectedNode) {
      this.cx.drawImage(this._image, mapNode.x, mapNode.y, this.Map.markerSize, this.Map.markerSize);
      this.nodeService.setNodeSelection(this.Map.mapId, selectedNode);
    } else {
      this.nodeService.setNodeSelection(this.Map.mapId, undefined);
    }
  }
}

