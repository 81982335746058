import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { NodesManagerService } from 'src/app/core/nodes-manager.service';
import { MapNode, IMapNode } from 'src/app/entities/MapNode';
import { IMap } from 'src/app/entities/IMap';

// https://codepen.io/WangWeiqiang/pen/Cadyu

@Component({
  selector: 'app-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.scss']
})
export class DrawComponent implements OnChanges, OnInit {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapId) {
      const mapId: SimpleChange = changes.mapId;
      console.log('prev value: ', mapId.previousValue);
      console.log('got name: ', mapId.currentValue);
      this.fetchMap();
      //this.Map = this.nodeService.getMap(this.MapName);
    }
  }
  // a reference to the canvas element from our template
  @ViewChild('drawingcanvas') public canvas: ElementRef;
  // setting a width and height for the canvas

  private cx: CanvasRenderingContext2D;
  @Input('mapId') mapId: string;
  @Input('map') Map: IMap;
  constructor(
    private nodeService: NodesManagerService
  ) {
    nodeService.mapNodesToHighlight.subscribe((highlight: any) => {
      if (highlight.target && highlight.nodes) {
        if (highlight.target === "draw") {
          highlight.nodes.forEach((node) => {
            this.drawNode(node);
          })
        }
      }
    })
  }

  ngOnInit() {
    console.log(this.mapId, this.Map);
    if (this.nodeService.isInitialized) {
      this.fetchMap();
    } else {
      this.nodeService.initialized.subscribe(() => {
        this.fetchMap();
      })
    }
  }
  fetchMap() {
    this.nodeService.fetchMap(this.mapId).then(map => {
      this.Map = map;
      this.loadCanvas()
    });
  }
  public loadCanvas() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    // set the width and height
    canvasEl.width = this.Map.width;
    canvasEl.height = this.Map.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // debugger;
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        // this.drawOnCanvas(prevPos, currentPos);
        const mapNode = this.nodeService.insertNode(this.Map.mapId, new MapNode(this.Map.mapId, currentPos.x, currentPos.y, this.Map.blockSize, this.Map.blockSize))
        mapNode.isRoute = true;
        this.drawNode(mapNode);
        // const mapNode = this.nodeService.deleteNode(this.Map.mapId, new MapNode(this.Map.mapId, currentPos.x, currentPos.y, this.Map.blockSize, this.Map.blockSize))
        // this.removeNode(mapNode);
      });
  }


  private drawNode(node: IMapNode) {
    if (!this.cx) { return; }
    if (node.x === 0 || node.x === this.Map.width || node.x === this.Map.width - this.Map.blockSize || node.y === 0 || node.y === this.Map.height || node.y === this.Map.height - this.Map.blockSize) { return; }

    this.cx.fillStyle = "#ff0000";
    this.cx.fillRect(node.x, node.y, node.w, node.h);
  }
  private removeNode(node: IMapNode) {
    if (!this.cx) { return; }
    if (node.x === 0 || node.x === this.Map.width || node.x === this.Map.width - this.Map.blockSize || node.y === 0 || node.y === this.Map.height || node.y === this.Map.height - this.Map.blockSize) { return; }

    this.cx.clearRect(node.x, node.y, node.w, node.h);
  }
  private drawSquareOnLocation(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ) {
    // incase the context is not set
    if (!this.cx) { return; }
    const NodeX = (currentPos.x - (currentPos.x % this.Map.blockSize)),
      //  nearestNodeX = (NodeX / blockSize),
      NodeY = (currentPos.y - (currentPos.y % this.Map.blockSize));
    //, nearestNodeY = (NodeY / blockSize);
    this.cx.fillStyle = "#ff0000";

    // actual 
    // var nearestNodeId = NodeY + "-" + NodeX;
    // ctx.fillRect(NodeX, NodeY, blockSize, blockSize);
    this.cx.fillRect(NodeX, NodeY, 25, 25);
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ) {
    // incase the context is not set
    if (!this.cx) { return; }

    // start our drawing path
    this.cx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from

      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  }

}
